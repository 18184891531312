<script>
export default {
    name: "Header",
};
</script>

<template lang="pug">
.header.flex.justify-between.items-center.p-2.bg-white.z-50.bg-gray-100.sticky.top-0
    .flex.items-center
        .relative
            a.inset-y-0.left-0.pl-3.py-1.flex.items-center(href="http://amitengineeringcompany.com/")
                img.w-40(:src="require('@assets/logo.png')")
</template>